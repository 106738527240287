body {
  margin: 0;
  padding: 0;
  font-family: light;
  max-width: 1400px;
  margin: auto;
}

body scrollbar {
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 3.125rem;
}

body scrollbar-thumb {
  border-radius: 3.125rem;
  box-shadow: inset 0 0 100px gray;
}

@font-face {
  font-family: bold;
  src: url(../src/assests/font-family/bold.otf);
}

@font-face {
  font-family: medium;
  src: url(../src/assests/font-family/medium.otf);
}

@font-face {
  font-family: light;
  src: url(../src/assests/font-family/light.otf);
}

@font-face {
  font-family: semiBold;
  src: url(../src/assests/font-family/semiBold.otf);
}

.light-theme {
  --primary-color: #000000;
  --secondary-color: #7a7a7a;
  --tertiary-color: #ffffff;
  --border-color: #d9d9d9;
  --primary-bgColor: #f5f5f5;
  --theme-color: linear-gradient(
    270deg,
    #227db6 3%,
    #855791 42.5%,
    #ea2063 85%
  );
}

.errorMessage {
  color: #ff2e2e;
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 5px 10px;
}

.fontGray {
  font-size: 16px;
  font-weight: 500;
  color: var(--secondary-color);
  font-family: medium;
  line-height: 1.5;
}

figure {
  margin: 0;
}

img {
  width: 100%;
  height: 100%;
}

.flag-dropdown {
  background: white !important;
  border-radius: 10px 0 0 10px !important;
}

input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
  &::-moz-appearance-calendar-picker-indicator {
    cursor: pointer;
  }
}

.container {
  border-radius: 30px;
  background: white;
  border: 1px solid #e9e9e9;
  padding: 20px;
}

.title {
  font-size: 18px;
  font-family: semiBold;
}

.tableContainer {
  overflow: auto;
  border-radius: 6px;
}

.tableContainer::-webkit-scrollbar {
  width: 6px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: #e3e2e2;
  border-radius: 10px;
  height: 10px;
}

.tableContainer::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

textarea {
  resize: none;
}

.link {
  color: #0d4182;
  cursor: pointer;
  font-family: medium;
  display: inline-block;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.red {
  color: red;
}

.chapter3 {
  text-align: left;
  font-size: 16px;
  font-family: medium;
  margin: 0;
  text-indent: 3ch;
}

.chapter4 {
  text-align: left;
  font-size: 16px;
  font-family: medium;
  margin: 0;
  text-indent: 3ch;
  color: #7a7a7a;
}

.chapter2 {
  text-align: left;
  font-size: 16px;
  font-family: medium;
  margin: 0;
  text-indent: 3ch;
}

.chapter1 {
  text-align: center;
  font-size: 30px;
  font-family: semiBold;
  margin: 0;
}

.imagecontainer {
  height: 80vh;
  width: 70%;
  padding: 20px;
  background-image: url("../../assests/images/somethingWentWrong.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: auto;
}

.imagePagenNotFoundcontainer {
  height: 70vh;
  width: 60%;
  padding: 20px;
  background-image: url("../../assests/images/page404.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: auto;
}
.backButton {
  margin: 30px 0 0 30px;
  width: 200px !important;
}

@import "../../styles/variables";

.btnContainer {
  border-radius: 10px;
  text-align: center;
  padding: 15px;
  outline: none;
  border: none;
  width: 100%;
  @include font(18px, var(--tertiary-color));
  font-family: semiBold;
  background: var(--theme-color);
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.primaryBtn {
  background: var(--theme-color);
  @include font(18px, var(--tertiary-color));
}

.secondaryBtn {
  background: var(--tertiary-color);
  color: var(--secondary-color);

  &:hover {
    background: var(--theme-color);
    color: var(--tertiary-color);
  }
}

.link {
  text-decoration: none;
}

.loader {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: loader 1s infinite linear;
  margin: 5px;
}
.loader::before,
.loader::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.loader::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: loader-before 1s infinite linear;
}
.loader::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: loader-after 1s infinite linear;
}

.isBorder {
  border: 1px solid #7a7a7a;
  color: #000000;
  width: 25%;
}

@keyframes loader-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes loader {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes loader-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

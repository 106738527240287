.notificationShow {
  visibility: visible;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  position: fixed;
  top: 14px;
  right: 12px;
  z-index: 10000;
  transform: 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
  background: #fefefe;

  .notification {
    position: relative;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 0 10px #999;
    opacity: 0.9;
    width: 100%;
    z-index: 100;

    .notificationHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fefefe;
      padding: 5px 10px;
      min-width: 200px;
      .title {
        display: flex;
        align-items: center;
        padding: 0rem 0.875rem;
      }
      .image {
        margin-right: 5px;
        width: 30px;
        display: flex;
        align-items: center;
      }

      .message {
        font-family: medium;
      }
    }
  }
}

.closeIcon {
  padding-top: 0.313rem;
  padding-left: 0.313rem;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
    visibility: hidden;
  }
  to {
    transform: translateX(0);
    visibility: visible;
  }
}
@keyframes toast-in-left {
  from {
    transform: translateX(0%);
    visibility: visible;
  }
  to {
    transform: translateX(100%);
    visibility: hidden;
  }
}

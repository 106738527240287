@mixin font($fontSize: 1rem, $color: var(--primary-color)) {
  font-size: $fontSize;
  color: $color;
}

@mixin gridColumn($columns: 2) {
  display: grid;
  grid-template-columns: repeat($columns, minmax(50px, 1fr));
  grid-gap: 20px;

  @media (max-width: 880px) {
    grid-template-columns: repeat(2, minmax(50px, 1fr));
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, minmax(50px, 1fr));
  }
}

@mixin flex($justifyContent: center, $alignContent: center) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignContent;
}

@mixin skeleton($width) {
  animation: skeleton-loading 1.2s linear infinite alternate;
  width: $width;
  border-radius: 20px;
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }

    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
}

@mixin toggleContainer() {
  background: #f5f5f5;
  width: 30px;
  height: 30px;
  @include flex();
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  padding: 5px;
  margin-left: auto;
}

@mixin dropDownList() {
  position: absolute;
  padding: 10px;
  background: white;
  width: 100px;
  border-radius: 20px;
  border: 1px solid #e9e9e9;
  right: 0;
  top: 50px;

  p {
    @include font(14px, #7a7a7a);
    font-family: bold;
    padding: 5px 10px;
    margin: 0;
    border-radius: 6px;
    &:hover {
      background: #f5f5f5;
      color: black;
      opacity: 0.6;
    }
  }
}
